<template>
  <b-card style="min-height:80vh">
    <div class="m-app-title-view mb-5">
      <span class="heading-title-view">Data Center</span>

      <div>
        <VueDatePicker
          v-model="dateData"
          type="month"
          format="MMMM-YYYY"
          placeholder="Choose date"
          @onChange="changeDate"
        />
      </div>
      <div class="demo-inline-spacing">

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="makeBackup"
        >
          Backup Database
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="openUndoRestore"
        >
          Undo Restore
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-info"
          @click="handleDatabaseEnable"
        >
          {{ is_database_enable ? 'Disable Maintanence Mode' : 'Enable Maintanence Mode' }}
        </b-button> -->
      </div>
    </div>

    <!-- Bootstrap table content section start here -->
    <b-table
      :fields="fields"
      :items="backups.data"
      striped
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      @row-clicked="showDetails"
    >
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ date(data.value) }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(filename)="data">
        {{ data.value }}
      </template>

      <template #cell(size)="data">
        <b-badge
          pill
          variant="info"
        >
          {{ data.value }} kb
        </b-badge>
      </template>

    </b-table>
    <b-pagination
      v-model="currentPage"
      class="mt-3"
      :total-rows="totalRows"
      :per-page="perPage"
      first-number
      last-number
      prev-class="prev-item"
      next-class="next-item"
      :align="right"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="18"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="18"
        />
      </template>
    </b-pagination>
    <!-- Bootstrap table content section end here -->
    <!-- File details -->
    <b-modal
      id="show-details"
      title="File Details"
      hide-footer
    >
      <div class="backup-file-box mb-1">
        <b-avatar variant="primary">
          <feather-icon
            icon="DatabaseIcon"
          />
        </b-avatar>
        <div class="file_content_main_view">
          <div class="file_content_view">
            <h5>{{ backup_details.filename }}</h5>
            <span class="size">{{ backup_details.size }} kb</span>
          </div>
          <span>{{ date(backup_details.created_at) }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          class="float-left"
          @click="deleteBackup"
        >
          Delete Backup
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="float-right"
          @click="restoreBackup"
        >
          Restore Backup
        </b-button>
      </div>

    </b-modal>
    <!-- File details  -->

    <!-- File details -->
    <b-modal
      id="show-undo-restore"
      title="File Details"
      hide-footer
    >
      <span>Select Restore File</span>
      <b-form-select
        v-model="undo_file"
        placeholder="Select Backup"
        :options="options"
        class="mb-2"
      />
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-warning"
        class="float-right"
        @click="undoRestoreBackup"
      >
        Undo Restore
      </b-button>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BTable, BBadge,
  BModal, BAvatar, BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import showToast from '@/mixins/showToast'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BModal,
    BBadge,
    BAvatar,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      is_database_enable: false,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      right: 'right',
      dateData: null,
      fields: [
        // A virtual column that doesn't exist in items
        'index',
        // A column that needs custom formatting
        { key: 'created_at', label: 'Backup Date',sortable:true },
        { key: 'filename', label: 'Filename',sortable:true },
        { key: 'size', label: 'File Size',sortable:true },
        // A virtual column made up from two fields
      ],
      backup_details: {},
      options: [],
      undo_file: '',
    }
  },
  computed: {
    backups() {
      return this.$store.state.chat.backups
    },
    restore_backups() {
      return this.$store.state.chat.backups
    },
    rows() {
      return this.$store.state.chat.rows
    },
    // currentPage: {
    //   get() {
    //     return this.$store.state.chat.currentPage
    //   },
    //   set(value) {
    //     return value
    //   },
    // },
  },
  created() {
    this.perPage = 10
  },

  mounted() {
    this.getBackup()
  },
  methods: {
    date(data) {
      return moment(data).format('Do, MMM YYYY, h:mm a')
    },
    changeDate() {
      const date = moment(this.dateData).format('MMMM-YYYY')
      this.$store.dispatch('chat/fetchBackups', { date })
    },
    allowedMonths: date => parseInt(date.split('-')[1], 10) % 2 === 0,
    getBackup() {
      const date = moment().format('MMMM-YYYY')
      this.$store.dispatch('chat/fetchBackups', { date }).then(() => {
        this.totalRows = this.backups.data.length
      })
    },
    handleDatabaseEnable() {
      this.is_database_enable = !this.is_database_enable
    },
    showDetails(data) {
      this.backup_details = data
      this.$bvModal.show('show-details')
    },
    openUndoRestore() {
      this.options = this.restore_backups.data.map(item => ({ value: item.file, text: item.filename })).filter(o => {
        const fileArr = o.value.split('/')
        const fileName = fileArr[fileArr.length - 1]
        return fileName.startsWith('R')
      })
      this.$bvModal.show('show-undo-restore')
    },
    undoRestoreBackup() {
      const data = { backup_details: this.undo_file, restore_type: 'restore' }
      this.$store.dispatch('chat/restoreDatabase', data).then(res => {
        this.showSuccess('Undoing last restore')
      })
      this.getBackup()
      this.$bvModal.hide('show-undo-restore')
    },
    makeBackup() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to backup database',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Create Backup!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('chat/makeBackup').then(res => {
            this.showSuccess('Backup operation has been initiated')
          })

          const $this = this
          clearInterval($this.getBackup())
          setTimeout(() => $this.getBackup(), 240)
        }
      })
    },
    restoreBackup() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to restore database',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, restore database!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = { backup_details: this.backup_details.file, restore_type: 'restore' }
          this.$store.dispatch('chat/restoreDatabase', data).then(res => {
            this.showSuccess('Retore operation has been initiated')
          })
          this.getBackup()
        }
      })
    },
    deleteBackup() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to delete backup',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete backup!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('chat/deleteDatabase', { file_path: this.backup_details.file }).then(res => {
            this.showSuccess('Backup deleted successfully')
            this.$bvModal.hide('show-details')
          })
          this.getBackup()
        }
      })
    },
  },
}
</script>

<style>

</style>
